import React from 'react';
import './footer.scss';
import Constants from '../../constants/constants';

const FooterComponent = (props) => {
    const {
        displayForPage
      } = props;

    return (
        <footer>
            <div className="container pt-3 pl-6 pb-5 footer-align">
                <div className="row">
                 { displayForPage ? <div className="col-12 col-md-6 col-lg-7">
                    {Constants.footer.COPY_RIGHTS_CONTENT}
                 </div>
                :
                <div className="col-12 col-md-6 col-lg-7">
                   {Constants.footer.COPY_RIGHTS_CONTENT}
                </div>
                }
                </div>
            </div>
        </footer>
    )
};

export { FooterComponent };