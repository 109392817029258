import React from 'react';

export const LoginHelpFullLinks = ({ PanelContent }) => {
    const renderDom = PanelContent ?
     (
        <>
            <h2 className='contentHeading'>{PanelContent.title}</h2>
            PanelContent.links && (
                <ul id="helpful-links">
                    {
                        PanelContent.links.map( link => 
                            (<li key={link.key}>
                                <a href={link.value}  target="_blank"  rel="noopener noreferrer">{link.key}</a>
                            </li>)
                        )
                    }
                </ul>
            )
        </>
    )
    : "";
    return renderDom;
};

export const CommBizUserGuides = ({ PanelContent }) => {
    const renderDom = PanelContent ? 
        (
            <>
                <h2 className='contentHeading'>{PanelContent.title}</h2>
                PanelContent?.links && (
                    <ul id="user-guides">
                        {
                            PanelContent.links.map( link => 
                                (<li key={link.key}>
                                    <a href={link.value}  target="_blank"  rel="noopener noreferrer">{link.key}</a>
                                </li>)
                            )
                        }
                    </ul>
                )
            </>
        )
        : "";
    return renderDom;
};

export const CommBizMobile = ({ PanelContent }) => {
    const renderDom = PanelContent ? (
                <div className='commbiz-mobile-section'>
                    <div className='col-12 col-sm-12 col-md-5 col-lg-12 commbiz-mobile-section-img-top mb-3 pl-0 pr-0'>
                        <img src={PanelContent.image.image} alt={PanelContent.image.displayText} />
                    </div>
                    <div id="comm-mobile">
                        <h2 className='contentHeading'>{PanelContent.title}</h2>
                        <p className='col-12 col-sm-12 col-md-7 col-lg-12 contentParagraph float-left'>{PanelContent.paragraph}
                        <span><a href={PanelContent.moretextlink[0].value} target="_blank"  rel="noopener noreferrer">{PanelContent.moretextlink[0].key}</a></span>
                        </p>
                    </div>
                    <div className='col-12 col-sm-12 col-md-5 col-lg-12 commbiz-mobile-section-img float-left pl-0 pr-0'>
                        <img src={PanelContent.image.image} alt={PanelContent.image.displayText} />
                    </div> 
                </div>
        ): "";

        return renderDom
};
