import {createStore,applyMiddleware,compose  } from 'redux';
import { createEpicMiddleware }  from 'redux-observable'
import { rootEpics } from '../epics/rootEpics';
import {rootReducer } from '../reducers/rootReducers';

const epicMiddleware  = createEpicMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  
export default function configureStore(history, intitalstate) {

    const store = createStore(
      rootReducer,
      intitalstate,  
      composeEnhancers(
        applyMiddleware(epicMiddleware)      
      )
    );
    
    epicMiddleware.run(rootEpics);    

    return store;
}
