/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HeaderComponent } from '../Header/header';
import { FooterComponent } from '../Footer/footer';
import ContentPanel from '../Logon/contentPanel';
import BrowserSupport from '../BrowserSupport/browsersupport';
import LoginPanel from '../../containers/logonPanelContainer';
import "@honeycomb-npm/honeycomb/dist/cdn/honeycomb.css";
import { getCookie } from './../../shared/utils/cookieUtils';
import { BioCatchEnvUrl, PROD_URL_DOMAIN, STAG_URL } from './../../constants/bioCatch';
import './layout.scss';
import {PageLoad} from './../../scripts/adobeDataLayer.js';
import { PAGE } from '../../constants/common';
import { useState } from 'react';
import trackLinkClicks from '../../scripts/adobeCJAHandler';


const Layout = props => {
    const isBioCatchEnabled = useSelector(store => store.bioCatch.isBioCatchEnabled);
    const isCJAEnabled = useSelector(store => store.adobeCJA.isAdobeCjaEnabled);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [scriptInjected, setScriptInjected] = useState(false);
    const [isBiocatchTriggered, setBiocatchTriggered] = useState(false);
    const [isCjaTriggered, setCjaTriggered] = useState(false);
  
    useEffect(() => {
        if (isBioCatchEnabled === 'Y' || isBioCatchEnabled === true) {
            if (!scriptInjected) {
                setScriptInjected(true);
                bioCatchScriptInjection(setScriptLoaded);
            }
            if (scriptLoaded) {
                if (!isBiocatchTriggered) {
                    setBiocatchTriggered(true);
                    triggerBiocatch();
                }               
            }
        }
    }, [isBioCatchEnabled, scriptLoaded]);

    useEffect(() => {
        if (isCJAEnabled) {           
            if (!scriptInjected) {
                setScriptInjected(true);
                bioCatchScriptInjection(setScriptLoaded);
            }
            if (scriptLoaded) {
                if (!isCjaTriggered) {
                    setCjaTriggered(true);
                    PageLoad("pageview", PAGE.LOGIN);
                }
            }
        }
    }, [isCJAEnabled,scriptLoaded]);

    useEffect(() => {
        if (isCJAEnabled) {           
            const cleanup = trackLinkClicks();
            return () => {
                cleanup();
            };
        }
    }, [isCJAEnabled]);
   

    return (
        <div>
            <HeaderComponent displayForPage />
            <div className="background-image"></div>
            <div className="mobile-container">
                <BrowserSupport />
                {/* {props.children} */}
                <LoginPanel />
                <ContentPanel />
                <FooterComponent displayForPage={true} />
            </div> 
        </div>
    )
}

export default Layout;

export const bioCatchScriptInjection = (setScriptLoaded) => {
    let bioCatchUrl = BioCatchEnvUrl['Non-Prod'];
    if (isStag()) {
        bioCatchUrl = BioCatchEnvUrl['Staging']
    }
    else if (isProd()) {
        bioCatchUrl = BioCatchEnvUrl['Prod'];
    }
    const loadScript = document.createElement('script');
    loadScript.setAttribute("src", bioCatchUrl);

    loadScript.onload = () => {
        setScriptLoaded(true);
    };

    document.head.appendChild(loadScript);
}


export const isProd = () => {
    return window.location.hostname.includes(PROD_URL_DOMAIN);
}

export const isStag = () => {
    return window.location.hostname.includes(STAG_URL);
}

export const triggerBiocatch = () => {
    const csid = getCookie(document.cookie, "csid");
    const pageTitle = document.getElementsByTagName('h1')[0].innerText;
    setTimeout(() => {
        try {
            if (typeof cdApi !== 'undefined') {
                cdApi.changeContext(pageTitle);
                cdApi.setCustomerSessionId(csid);
            }
        } catch (e) {
            console.log(e);
        }
    }, 500);
};