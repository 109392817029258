import { linkClickedInternal, linkClickedExternal } from '../scripts/adobeDataLayer';

const trackLinkClicks = () => {
    const handleClick = (event) => {            
        var clickedElement = event.target;
        var isExternalLink = false;
        var linkType = 'link';
        var linkValue = '';
        var isLink = false;
        if (clickedElement.tagName.toLowerCase() === "a") {
            isLink = true;
            linkValue = clickedElement.textContent;
            var link = clickedElement.href;
            if (!link.startsWith(window.location.origin)) {
                isExternalLink = true;
            }
        }
        else if (clickedElement.tagName.toLowerCase() === "button") {
            isLink = true;
            linkType = "button";
            linkValue = clickedElement.textContent;
        }
        else if (clickedElement.tagName.toLowerCase() === "input" && (clickedElement.type.toLowerCase() === "submit" || clickedElement.type.toLowerCase() === "button")) {
            isLink = true;
            linkType = "button";
            linkValue = clickedElement.value;
        }

        if (isLink) {
            var linkPosition = getElementPosition(clickedElement);
            if (isExternalLink)
                linkClickedExternal(linkValue, linkType, linkPosition);
            else
                linkClickedInternal(linkValue, linkType, linkPosition);
        }
    }
    document.body.addEventListener('click', handleClick);
    return () => {
        document.body.removeEventListener('click', handleClick);
    };
}

function getElementPosition(element) {
    var rect = element.getBoundingClientRect();
    var viewportCenter = window.innerHeight / 2;
    if (rect.top < viewportCenter && rect.bottom < viewportCenter) {
        return "top";
    }
    else if (rect.top > viewportCenter && rect.bottom > viewportCenter) {
        return "bottom";
    }
    else {
        return "middle";
    }
}

export default trackLinkClicks;