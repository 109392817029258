import React, { useState, useEffect } from "react";
import "./browsersupport.scss";

const BrowserSupport = () => {
  const [isSupported, setIsSupported] = useState(true);
  const unsupportedBrowsers = {
    Chrome: 77,
    Firefox: 70,
    Edge: 16,
    Opera: 50,
    Safari: 10
  };

  useEffect(() => {
    var currentBrowser = (function () {
      var ua = navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];

      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: "IE", version: tem[1] || "" };
      }

      if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) {
          return { name: tem[1].replace("OPR", "Opera"), version: tem[2] };
        }
      }

      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }
      return { name: M[0], version: M[1] };
    })();

    if (unsupportedBrowsers.hasOwnProperty(currentBrowser.name)) {
      if (
        parseInt(currentBrowser.version) >
        unsupportedBrowsers[currentBrowser.name]
      ) {
        setIsSupported(true);
      } else {
        setIsSupported(false);
      }
    } else {
      setIsSupported(false);
    }

    if (window.location.href.indexOf("netlock") > -1) {
      setIsSupported(true);
    }
  }, []);

  return (
    <div>
      {!isSupported && (
        <div className="browser-support">
          <span>
            For added security, you need to update your browser.
            <a
              href="https://www.commbank.com.au/business/online-banking/commbiz/update-your-browser.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Here's how
            </a>
          </span>
        </div>
      )}
    </div>
  );
};

export default BrowserSupport;
