import React, { useState } from 'react';
import { Dialogue, Pictogram, Icon } from '@honeycomb-npm/honeycomb-react';
import data from './dialogueData.json';

const DialogueWindow = (props) => {
    const [closeModal, setCloseModal] = useState(true);
    const onCloseModal = () => {
        if (props.resetStateValue) {
            props.resetStateValue();
        }
        setCloseModal(false);
    }

    let iconType = (icon, prop) => {
        const components = {
            Pictogram: <Pictogram name={prop} />,
            Icon: <Icon symbol={prop} type="svgfile" />
        };
        return components[icon];
    }

    return (
        Object.keys(data)
            .filter(key => key === props.status)
            .map((prop) => {
                if (data[prop].dialogueType === 'Dialogue') {
                    return (
                        <Dialogue
                            id='modal-1'
                            key={prop}
                            title={data[prop].title}
                            message={data[prop].message}
                            isOpen={closeModal}
                            ariaModal
                            buttonDefault={{
                                children: data[prop].textAffirmative,
                                onClick: () => {
                                    if (data[prop].affirmativeUrl) {
                                        window.location.href = data[prop].affirmativeUrl;
                                    }
                                    else {
                                        onCloseModal();
                                    }
                                }
                            }}
                            buttonSubtle={{
                                children: data[prop].textDismissive,
                                onClick: () => {
                                    if (data[prop].dismissiveUrl) {
                                        window.location.href = data[prop].dismissiveUrl;
                                    }
                                    else {
                                        onCloseModal();
                                    }
                                }
                            }}
                            icon={{
                                element: iconType(data[prop].iconType, data[prop].iconProp),
                                height: data[prop].iconHeight,
                                width: data[prop].iconWidth
                            }}
                            closeModal={e => e}
                        />
                    );
                }
                else
                    return (
                        <Dialogue
                            id='modal-1'
                            key={prop}
                            title={data[prop].title}
                            message={data[prop].message}
                            isOpen={closeModal}
                            ariaModal
                            buttonDefault={{ children: data[prop].textAffirmative, onClick: onCloseModal }}
                            icon={{
                                element: iconType(data[prop].iconType, data[prop].iconProp),
                                height: data[prop].iconHeight,
                                width: data[prop].iconWidth
                            }}
                            closeModal={e => e}
                        />
                    );
            })
    );
}

export default DialogueWindow;