import React from 'react';
import { useSelector } from 'react-redux';
import { LoginHelpFullLinks, CommBizUserGuides, CommBizMobile } from './contentLink';
import { Spinner } from '@honeycomb-npm/honeycomb-react';
import './content.scss';

const ContentPanel = () => {
    const CMS = useSelector(state => state.login.CMS);
    const isContentLoading = useSelector(state => state.login.isContentLoading);
    return (
        <section>
            <div className="container">
                {
                    !!CMS ?
                    <div className="row black-content-wrapper pt-3 p-1 pb-5">
                        <div className={isContentLoading ? "spinner-overlay" : "hidden-element"} >
                            <Spinner isVisible={isContentLoading} />
                        </div>
                        <div className="col-12 col-sm-12 col-lg-4 black-content">
                                <LoginHelpFullLinks PanelContent={CMS.Panel1} />
                        </div>
                        <div className="col-12 col-sm-12 col-lg-4 black-content">
                                <CommBizUserGuides PanelContent={CMS.Panel2} />
                        </div>
                        <div className="col-12 col-sm-12 col-lg-4 black-content">
                                <CommBizMobile PanelContent={CMS.Panel3}/>
                        </div>
                    </div>
                    : null
                }
            </div>
        </section>
    )
};

//need to decide on a **safe** way to set the inner html using react.
// react-render-html review in progress..
//const rootElement = document.getElementById('black-content-1');
//ReactDOM.render(renderHTML(LoginHelpFullLinks),rootElement);

export default ContentPanel;