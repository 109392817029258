import React from 'react';
import { Icon } from "@honeycomb-npm/honeycomb-react";

const LoginErrorPannel = props => {
  return (
    <div className="err-msg pt-4 pb-4 px-2 px-sm-4">
      <div className="err-ico" aria-invalid="true" aria-hidden="true" tabindex="0">
        <Icon symbol="messageAlert48" type="svgfile" id="ErrorMessage" screenreader="alert" />
        <span className="ml-1">{props.errorMessage}</span>
      </div>
    </div>
  );
}

export { LoginErrorPannel };