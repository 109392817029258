export const LoginValidationStatus = {
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  AUTHENTICATED: 'AUTHENTICATED',
  CHANGE_TEMPORARY_PASSWORD: 'CHANGE_TEMPORARY_PASSWORD',
  LOGIN_ID_MISSING: 'LoginIdMissing',
  PASSWORD_MISSING: 'PasswordMissing',
  PAGE_TIMEOUT_EXPIRED: 'PageTimeoutExpired',
  EXCEPTION: 'EXCEPTION',
  TOKEN_REQUIRED : 'TOKEN_REQUIRED',
  GLOBAL_EXCEPTION : 'GLOBAL_EXCEPTION',
  MERGED_USER:'MERGED_USER'
};

export const ErrorMessage = {
  PLEASE_ENTER_YOUR_LOGIN_ID: "Enter your Log on ID",
  PLEASE_ENTER_YOUR_PASSWORD: "Enter your password",
  PLEASE_ENTER_YOUR_DIALOGUE_TOKEN_PASSWORD: "Enter your token password.",
  INVALID_CREDENTIAL: "Your log on ID or password is incorrect. For your security, we'll lock your CommBiz account if you enter incorrect details too many times.",
  INVALID_TOKEN_CREDENTIAL : "Your password or token password is incorrect. For your security, we'll lock your CommBiz account if you enter incorrect details too many times.",
  PAGE_TIMEOUT_EXPIRED: 'It took you a while to log on. You\'ll need to enter your details again.'
};