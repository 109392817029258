
import axiosClient from '../shared/utils/axiosClient';
import * as EndPoints from '../constants/Urls';
import { DB_KILL_SWITCH_NAME_PLACEHOLDER } from '../constants/common';
import { BIOCATCH_DB_KILL_SWITCH_NAME } from '../constants/bioCatch';
import { setBiocatchEnabledStatus } from '../redux/actions/bioCatchAction';

const BioCatchService = {
    getDBKillSwitchForBioCatch: async (dispatch) => {
        await axiosClient.get(EndPoints.getDBKillSwitch.replace(DB_KILL_SWITCH_NAME_PLACEHOLDER, BIOCATCH_DB_KILL_SWITCH_NAME))
            .then(response => {
                if (response.data.data.isEnabled) {
                    dispatch(setBiocatchEnabledStatus(response.data.data.isEnabled));
                }
            })
            .catch(error => {
                if (error) {
                    dispatch(setBiocatchEnabledStatus(false));
                }
            });
    }
}

export default BioCatchService;