import {connect} from 'react-redux';
import LoginPanel from '../components/Logon/logonPanel';
import { loginUser, 
         clearInputValues, 
         getValidationFocus, 
         clearValidationStatus,
         resetStateValue,
         setLoginId,
         setLoginButtonClicked,
         getFooterContent }  from '../redux/actions/logonActions';

const mapStateToProps = (state, ownProps) => {
    return ({  
        loginId : state.login.loginId,   
        isLogInButtonClicked: state.login.isLogInButtonClicked,
        loginresponse: state.login,
        isLoading: state.login.isLoading,
        isLoadingTokenDialogue: state.login.isLoadingTokenDialogue,
        validationFieldName: state.login.validationFieldName,
        isValidationFocus: state.login.isValidationFocus,
        validationStatus: state.login.validationStatus,
    })
}

const mapDispatchToProps = (dispatch) =>
 ({
     loginUser:(payload) => dispatch(loginUser(payload)),
     getValidationFocus: value => dispatch(getValidationFocus(value)),
     clearInputValues: ()=> dispatch(clearInputValues()),
     clearValidationStatus: ()=> dispatch(clearValidationStatus()),
     resetStateValue: () => dispatch(resetStateValue()),
     setLoginId: value => dispatch(setLoginId(value)),
     setLoginButtonClicked: value => dispatch(setLoginButtonClicked(value)),
     getFooterContent: () => dispatch(getFooterContent()),
 });

export default connect(mapStateToProps, mapDispatchToProps)(LoginPanel);
