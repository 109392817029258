import axios from 'axios';
import { getCookie } from './cookieUtils';

const TIMEOUT = 30000
 
function isContentTypeJson(response) {
    const contentType = response.headers['content-type'];
    return contentType && contentType.indexOf('application/json') !== -1;
  }
  
  function parseError(response) {
    const error = new Error('Error while calling Api');
    error.status = response.status;
  
    if (isContentTypeJson(response)) {
      return response.error;
    }
  
    return error;
  }

  function getAbsoluteUrl(url) {
    let a;
    if (!a) a = document.createElement('a');
    a.href = url;
    return a.href;
  }
  
  function parseJSON(response) {
    if (response == null) {
      return null;
    }  
    return response;
  }

  function callAxios(url, options) {
    const aUrl = url.startsWith('http') ? url : getAbsoluteUrl(url);
    const aOptions = options || {};
    aOptions.credentials = 'include';

    const token = getCookie(document.cookie, 'XSRF-TOKEN');
    axios.defaults.headers.common['X-XSRF-TOKEN'] = token;
    axios.interceptors.response.use((response) => {
      if(response.status === 204) {
        return response;
      }
      if (isContentTypeJson(response)) {
        return response;
      } 
      
      throw parseError(response);
    }, (error) => {
      if (error && error.response && error.response.status === 401) {
        window.location.reload();
      }
  
      return Promise.reject(error);
    });
  
    return axios({ url: aUrl, ...aOptions, timeout: (aOptions.timeout || 30000) })
      .then(parseJSON);
  }
  
class axiosClient {
  static get(url) {
    const options = {
      method: 'GET',      
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: TIMEOUT,
    };
    return callAxios(url, options);
  }

  static post(url, req) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: TIMEOUT
    };

    if (req) {
      options.data = req;
    }

    return callAxios(url, options);
  }
}

export default axiosClient;