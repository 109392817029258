import {adobepageconfig} from '../scripts/adobePageConfig.logon';
import {AdobeCJAEvents} from '../constants/adobeCJA';
import { PAGE } from '../constants/common';

window.adobeDataLayer = window.adobeDataLayer || [];
var pageconfig = adobepageconfig || {};

function PageLoad(eventname, pagename, key, payload) {  
    var dataLayer = {
        event: eventname,
        page: {
            pageinfo: {
                name: pagename && pageconfig.pages[pagename] ? pageconfig.pages[pagename].pageName : "",
                stack: pageconfig.stack,
                section: pagename && pageconfig.pages[pagename] ? pageconfig.pages[pagename].section : "",
                virtualpagename: pagename && pageconfig.pages[pagename] ? pageconfig.pages[pagename].section ?
                    pageconfig.site + ":" + pageconfig.pages[pagename].section + ":" + pageconfig.pages[pagename].pageName :
                    pageconfig.site + ":" + pageconfig.pages[pagename].pageName : "",
                url: window.location.href,
                site: pageconfig.site,
                platform: "Web",
                channel: "Commbiz Private"
            }
        },
        user: {
            userinfo: {
                id: "",                
                loginstatus : "unauthenticated",
                serviceinfo: {
                    id: ""
                }             
            }
        },
        marketing: {
            trackingcode: "",
            internalbanner: "",
            broadcastmsg: ""
        }
    };
    if (key != null && payload != null)
        dataLayer[key] = payload;   
    window.adobeDataLayer.push(dataLayer);
}

var linkClickedInternal = function linkClickedInternal(linkText, linkType, linkPosition) {
    linkClick(AdobeCJAEvents.InternalLinkClick, linkText, linkType, linkPosition);
};

var linkClickedExternal = function linkClickedExternal(linkText, linkType, linkPosition) {
    linkClick(AdobeCJAEvents.ExternalLinkClick, linkText, linkType, linkPosition);
};

var linkClick = function linkClick(event, linkText, linkType, linkPosition) {
    var payLoad = {
        customlinktext: linkText,
        customlinktype: linkType,
        customlinkposition: linkPosition
    };
    PageLoad(event,PAGE.LOGIN, 'linkclick', payLoad);
};

export {PageLoad,linkClickedInternal,linkClickedExternal};