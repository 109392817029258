import React, { useState, useRef } from 'react';
import './dialogueTokenMandatory.scss';
import { ErrorMessage } from '../../constants/logonValidation';
import { DialogueTokenMandatoryErrorPanel } from './dialogueTokenMandatoryErrorPanel';
import {
	Dialogue, Icon,
	ButtonAction,
	ButtonActionEmphasis,
	Spinner
} from '@honeycomb-npm/honeycomb-react';

const DialogueTokenMandatory = (props) => {
  const dialoguePassword = useRef();
	const dialoguetoken = useRef();
	const [closeModal, setCloseModal] = useState(true);
	const { isLoadingTokenDialogue } = props;

	let validateCredential = true;

	const [isValidCredential, setValidCredential] = useState(true);

	const onCloseModal = () => {
		setCloseModal(false);
		props.clearInputValues();
	};

	const ClearValues = () => {
		dialoguePassword.current.value = '';
		dialoguetoken.current.value = '';
	}

	const onSubmit = () => {
		const spacePattern = /\s/;

		if ((dialoguePassword.current && spacePattern.test(dialoguePassword.current.value))
			|| (dialoguetoken.current && spacePattern.test(dialoguetoken.current.value))) {
			setValidCredential(false);
			validateCredential = false;
		}
		else {
			validateCredential = true;
		}

		if (validateCredential) {
			if (dialoguePassword.current.value && dialoguetoken.current.value) {
				ClearValues();
				setValidCredential(true);
				props.onSubmit();
			}
			else {
				setValidCredential(false);
				ClearValues();
			}
		}
		else {
			ClearValues();
		}

	};

	return (
		<Dialogue
			id="token-mandatory-modal"
			title="" // This is required or there will be an error msg in unit test
			isOpen={closeModal}
			ariaModal
			children={
				<div>
					<div
						className={isLoadingTokenDialogue ? 'modal-spinner-overlay' : 'hidden-element'}
					>
						<Spinner isVisible={isLoadingTokenDialogue} />
					</div>
					<div>
						<h1 className="token-modal-header mb-4">Enter your token password</h1>
						<div className="token-modal-body">
							<div className="token-modal-logon-id-layout">
								<p className="token-modal-logon-id-label mb-0">Log on ID</p>
								<p className="token-modal-logon-id-text mb-0">
									{props.loginid}
								</p>
							</div>
							<div className="mb-6">
								<div className="token-modal-input-layout">
									<div className="token-modal-input-password">
										<label htmlFor="dialoguePassword">Password</label>
										<input
											id="dialoguePassword"
											className="form-control"
											name="dialoguePassword"
											aria-required="true"
											aria-describedby="error-password"
											type="password"
											onChange={(event) => props.onChange(event)}
											ref={dialoguePassword}
										/>
										<div aria-hidden="false" id="error-password">
											{((!isValidCredential) || (props.validation && !props.validationpassword)) && (
												<DialogueTokenMandatoryErrorPanel
													errorMessage={ErrorMessage.PLEASE_ENTER_YOUR_PASSWORD}
												/>
											)}
										</div>
									</div>
									<div className="token-modal-input-token">
										<label htmlFor="dialogueToken">Token Password</label>
										<input
											id="dialogueToken"
											className="form-control"
											aria-required="true"
											aria-describedby="error-token-password"
											name="dialogueToken"
											type="password"
											onChange={(event) => props.onChange(event)}
											ref={dialoguetoken}
										/>
										<div aria-hidden="false" id="error-token-password">
											{((!isValidCredential) || (props.validation && !props.validationtoken)) && (
												<DialogueTokenMandatoryErrorPanel
													errorMessage={
														ErrorMessage.PLEASE_ENTER_YOUR_DIALOGUE_TOKEN_PASSWORD
													}
												/>
											)}
										</div>
									</div>
								</div>
								<div role="status" aria-live="polite">
									{(isValidCredential) && (!props.validation && props.validationErrorMessage) && (
										<DialogueTokenMandatoryErrorPanel
											errorMessage={props.validationErrorMessage}
										/>
									)}
								</div>
							</div>
						</div>
						{!isLoadingTokenDialogue &&
							<div className="token-modal-btn-layout">
								<ButtonActionEmphasis
									className="token-modal-btn-done"
									onClick={onSubmit}
								>
									Done
								</ButtonActionEmphasis>

								<ButtonAction
									className="token-modal-btn-cancel"
									onClick={onCloseModal}
								>
									Cancel
								</ButtonAction>
							</div>
						}
					</div>
				</div>
			}
			icon={{
				element: <Icon symbol="logoCBASymbol64" type="svgfile" />,
				height: 64,
				width: 64,
			}}
			closeModal={(e) => e}
		/>
	);
};

export default DialogueTokenMandatory;
