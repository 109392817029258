var adobepageconfig = {
    enable: false,//implicitly invokees pageload in BDT2 app
    site: "cbz",
    stack: "bdt2",
    pages: {
        "commbiz:application:login": {
            section: "logon",
            pageName: "commbiz:application:login",
            pageTitle: "CommBiz Application Login"
        },       
        "commbiz:application:forgotpassword": {
            section: "logon",
            pageName: "commbiz:application:forgotpassword",
            pageTitle: "forgot-password"
        },
        "commbiz:application:signout": {
            section: "logon",
            pageName: "commbiz:application:signout",
            pageTitle: "signout"
        }        
    }
}

export {adobepageconfig};