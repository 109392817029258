import { SET_ADOBE_CJA_ENABLED_STATUS } from "../../constants/adobeCJA";

const initialstate = {
    isAdobeCjaEnabled: false
}

const adobeCja = (state = initialstate, action) => {
    switch (action.type) {
        case SET_ADOBE_CJA_ENABLED_STATUS: {
            return { ...state, isAdobeCjaEnabled: action.data }
        }
        default: return state;
    }
}

export default adobeCja;
