export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const CLEAR_INPUT_VALUES = 'CLEAR_INPUT_VALUES';
export const GET_VALIDATION_FOCUS = 'GET_VALIDATION_FOCUS';
export const CLEAR_VALIDATION_STATUS = 'CLEAR_VALIDATION_STATUS';
export const SET_LOGIN_ID = 'SET_LOGIN_ID';
export const SET_LOGIN_BUTTON_CLICKED = 'SET_LOGIN_BUTTON_CLICKED';
export const RESET_STATE_VALUE = 'RESET_STATE_VALUE';
export const SET_FOOTER_CONTENT = 'SET_FOOTER_CONTENT';
export const GET_FOOTER_CONTENT = 'GET_FOOTER_CONTENT';
export const FOOTER_CONTENT_FAIL = 'FOOTER_CONTENT_FAIL';
export const SWITCHES_API_ERROR = 'SWITCHES_API_ERROR';

