import { SET_BIOCATCH_ENABLED_STATUS } from "../../constants/bioCatch";

const initialstate = {
    isBioCatchEnabled: false
}

const bioCatch = (state = initialstate, action) => {
    switch (action.type) {
        case SET_BIOCATCH_ENABLED_STATUS: {
            return { ...state, isBioCatchEnabled: action.data }
        }
        default: return state;
    }
}

export default bioCatch;

