import React from 'react';
import './header.scss';
import Constants from '../../constants/constants';
import logo from '../../images/svg/CommbizLogo.svg';

const HeaderComponent = (props) => {
    const {
      displayForPage
    } = props;

    return (
      <header>
        <div className="logo ml-4">
          <img src={logo} alt="Commonwealth bank logo" />
          <span className="pl-4"> {Constants.commbiz} </span>
        </div>
         {displayForPage ?  <a 
          className="register-link mr-lg-4 mr-3"
          href={Constants.url.REGISTER_ONLINE_COMMBIZ}
        >
          {Constants.header.REGISTER_ONLINE_FOR_COMMBIZ}
        </a>:
         null
        }
      </header>
    );
  }

export {HeaderComponent};
