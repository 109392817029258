import { nanoid } from 'nanoid';
import Cookies from 'universal-cookie';
import { CSID_LENGTH } from '../../constants/bioCatch';

export function getCookie(cookie, name) {
  return cookie.split('; ').reduce((r, v) => {
    const i = v.indexOf('='); // find first = separator
    const parts = [v.slice(0, i), v.slice(i + 1)]; // slice cookie that includes = as part of value
    const value = parts[0] === name ? decodeURIComponent(parts[1]) : r;
    return value;
  }, '');
}

export function findParamValue(string, key) {
  return string.split('&').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === key ? parts[1] : r;
  }, '');
}


export function generateSessionID() {
  return nanoid(CSID_LENGTH);
}

export function setGUIDInCookie() {
  let cookies = new Cookies();
  let uniqueGUID = generateSessionID();
  cookies.set('csid', uniqueGUID, { path: '/' });
}