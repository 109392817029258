import { of, from } from 'rxjs';
import { ofType } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { loginUserUrl } from '../../constants/Urls';
import { loginUserSuccess } from '../actions/logonActions';
import axiosClient from '../../shared/utils/axiosClient';
import { LOGIN_USER, LOGIN_USER_FAIL } from '../../constants/logonActionTypes';

export const LoginUserEpic = (action$) => {
    return action$.pipe(
    ofType(LOGIN_USER),
    mergeMap(action => from(axiosClient.post(loginUserUrl, action.payload)).pipe(
    map(response => loginUserSuccess(response)),
    catchError(error => of({
                type: LOGIN_USER_FAIL,
                payload: { error }
            }
    )))),
)}