import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { map, flatMap, catchError } from 'rxjs/operators';
import { content } from '../../constants/Urls';
import { setFooterContent } from '../actions/logonActions';
import axiosClient from '../../shared/utils/axiosClient';
import { GET_FOOTER_CONTENT, FOOTER_CONTENT_FAIL } from '../../constants/logonActionTypes';
import { CONTENT_UTILITY_PLACEHOLDER, CONTENT_UTILITY_LOGON_CONTENT } from '../../constants/common';

export const LoginFooterContentEpic = (action$) => {
    return action$.pipe(
    ofType(GET_FOOTER_CONTENT),
    flatMap(() => axiosClient.get(content.replace(CONTENT_UTILITY_PLACEHOLDER, CONTENT_UTILITY_LOGON_CONTENT))),
    map(response => setFooterContent(response)),
    catchError(error => of({
        type: FOOTER_CONTENT_FAIL,
        payload: { error }
    }))
)}