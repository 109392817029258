export const BioCatchEnvUrl = {
    "Non-Prod": "https://assets.adobedtm.com/340f0e73ee43/02d580af9e63/launch-d8fa0bf4ffe0-development.min.js",
    "Staging": "https://assets.adobedtm.com/340f0e73ee43/02d580af9e63/launch-c7d755125e58-staging.min.js",
    "Prod": "https://assets.adobedtm.com/340f0e73ee43/02d580af9e63/launch-d5cdb2f058b1.min.js"
}

export const SET_BIOCATCH_ENABLED_STATUS = "SET BIOCATCH ENABLED STATUS";

export const PROD_URL_DOMAIN = 'commbiz.commbank.com.au';
export const STAG_URL = 'commbiz.staging.commbank.com.au';
export const CSID_LENGTH = 36;
export const BIOCATCH_DB_KILL_SWITCH_NAME = "COMMBIZ_BIOCATCH_ENABLED";