import React, { useState, useEffect } from 'react';
import { ButtonActionEmphasis } from '@honeycomb-npm/honeycomb-react';
import './logon.scss';
import Constants from '../../constants/constants';
import { LoginValidationStatus, ErrorMessage } from '../../constants/logonValidation';
import { redirectToRegisterUrl } from '../../constants/Urls';
import { LoginErrorPannel } from './logonErrorPanel';
import DialogueWindow from '../Dialogue/dialogue';
import DialogueTokenMandatory from '../Dialogue/dialogueTokenMandatory';
import { Spinner } from '@honeycomb-npm/honeycomb-react';
import * as support from './support';
import { SECURITY_FORGOTPASSWORD } from '../../constants/RoutePaths';

const LoginPanel = props => {
  const initialLoginState = {
    loginId: '',
    password: '',
    token: '',
    dialoguePassword: '',
    dialogueToken: ''
  };
  const [loginData, setLoginData] = useState(initialLoginState);
  const [submitted, setSubmitted] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [dialogueValidation, setDialogueValidation] = useState(false);
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
  const [supportData, setSupportData] = useState(null);
  
  const invalidStatus = [
    LoginValidationStatus.INVALID_CREDENTIALS,
    LoginValidationStatus.LOGIN_ID_MISSING,
    LoginValidationStatus.PASSWORD_MISSING,
    LoginValidationStatus.PAGE_TIMEOUT_EXPIRED,
    LoginValidationStatus.EXCEPTION,
    LoginValidationStatus.GLOBAL_EXCEPTION
  ]

  const {
    loginUser,
    validationStatus,
    isValidationFocus,     
    isLoading,
    isLoadingTokenDialogue,
    getFooterContent,
  }  = props;

  useEffect(() => {
    support.default(function callback(response) {
      setSupportData(response);
    });
    getFooterContent();
  }, [getFooterContent]);

  const handleChange = e => {
    e.preventDefault();
    setSubmitted(false);
    const { name, value } = e.target;
    setLoginData(({ ...loginData, [name]: value }));
    props.getValidationFocus(false);
    props.clearValidationStatus('');
  }

  const handleFocus = e => {
    e.preventDefault();
    setInputFocus(e.target.name);
  }

  const handleBlur = e => {
    e.preventDefault();
    setInputFocus("");
  }
    
  const logonStatus = props.loginresponse.data.logonStatus;
  const errorTarget = props.loginresponse.error.target;

  useEffect(() => {
    // reset inputs when there is an error
    if (errorTarget || invalidStatus.includes(validationStatus)) {
      setLoginData(initialLoginState);
    }
  }, [errorTarget, validationStatus, setLoginData]);

  const submitUser = e => {
    e.preventDefault();
    setSubmitted(true);
    props.getValidationFocus(false);
    props.clearValidationStatus('');
    props.setLoginId(loginData.loginId);
    props.setLoginButtonClicked(true);

    loginUser({
      loginId: loginData.loginId,
      password: loginData.password,
      token: loginData.token,
      title: 'SupportLog',
      data: supportData
    });

    setIsTokenModalOpen(false);
    props.resetStateValue();
  }

  const tokenMandatoryuser = () => {
    props.setLoginButtonClicked(false);
    if (loginData.dialoguePassword && loginData.dialogueToken) {
      loginUser({
        loginId: props.loginId,
        password: loginData.dialoguePassword,
        token: loginData.dialogueToken,
        title: 'SupportLog',
        data: supportData
      });

      setDialogueValidation(false);
      props.resetStateValue();
    }
    else {
      setDialogueValidation(true);
    }
  }

  const clearInputs = () => {
    setLoginData(initialLoginState);
    props.resetStateValue();
    setIsTokenModalOpen(false);
  }
  const isRenderDialogueComponent = () => {
    if ((errorTarget !== LoginValidationStatus.LOGIN_ID_MISSING &&
      errorTarget !== LoginValidationStatus.PASSWORD_MISSING &&
      errorTarget !== LoginValidationStatus.PAGE_TIMEOUT_EXPIRED &&
      errorTarget !== null) ||
      errorTarget === LoginValidationStatus.EXCEPTION) {
      return (<DialogueWindow status={LoginValidationStatus.GLOBAL_EXCEPTION} resetStateValue={() => setLoginData(initialLoginState)} />);
    }
    else if ((logonStatus === LoginValidationStatus.TOKEN_REQUIRED || isTokenModalOpen) && !errorTarget &&
      (logonStatus === LoginValidationStatus.TOKEN_REQUIRED ||
        logonStatus === LoginValidationStatus.INVALID_CREDENTIALS || logonStatus === null)) {
      return (
        <DialogueTokenMandatory
          isLoading={isLoading}
          isLoadingTokenDialogue={isLoadingTokenDialogue}
          loginid={props.loginId}
          isLoginButtonClicked={props.isLoginButtonClicked}
          onChange={(e) => handleChange(e)}
          onSubmit={() => [setLoginData(initialLoginState), setIsTokenModalOpen(true), setSubmitted(true), tokenMandatoryuser()]}
          validation={dialogueValidation}
          validationpassword={loginData.dialoguePassword}
          validationtoken={loginData.dialogueToken}
          validationErrorMessage={validationStatus === LoginValidationStatus.INVALID_CREDENTIALS && ErrorMessage.INVALID_TOKEN_CREDENTIAL}
          clearInputValues={() => [setLoginData(initialLoginState), setSubmitted(false), setIsTokenModalOpen(false), setDialogueValidation(false), props.resetStateValue()]}
        />);
    }
    else if (logonStatus) {
      return (<DialogueWindow resetStateValue={() => clearInputs()} status={logonStatus} />);
    }
  }

  const keyPressed = (e) => {
    if (e.key === "Enter") {
      submitUser(e);
    }
  }
  return (
    <section className="login-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-7 pl-4 login-headline">
            <h1 className="Logon_text">
              {Constants.loginPannel.WELCOME_TO_COMMBIZ}
            </h1>
            <p className="Text20DarkLeft">
              {Constants.loginPannel.LOGIN_PAGE_CONTENT}
            </p>
          </div>
          <div aria-hidden="false" className={`col-12 col-sm-12 col-md-6 col-lg-5 login-card mb-3 ${isValidationFocus && !isTokenModalOpen ? "validationFocus" : ""}`}>
            <div className={isLoading ? "spinner-overlay" : "hidden-element"} >
              <Spinner isVisible={isLoading} />
            </div>

            {submitted && validationStatus === LoginValidationStatus.LOGIN_ID_MISSING && (
              <LoginErrorPannel errorMessage={ErrorMessage.PLEASE_ENTER_YOUR_LOGIN_ID} />
            )}
            {submitted && validationStatus === LoginValidationStatus.PASSWORD_MISSING && (
              <LoginErrorPannel aria-hidden="false" errorMessage={ErrorMessage.PLEASE_ENTER_YOUR_PASSWORD} />
            )}
            {submitted && !isTokenModalOpen && validationStatus === LoginValidationStatus.INVALID_CREDENTIALS && (
              <LoginErrorPannel aria-hidden="false" errorMessage={ErrorMessage.INVALID_CREDENTIAL} />
            )}
            {submitted && validationStatus === LoginValidationStatus.PAGE_TIMEOUT_EXPIRED && (
              <LoginErrorPannel errorMessage={ErrorMessage.PAGE_TIMEOUT_EXPIRED} />
            )}
            <div className={`float-panel floating-label-wrap ${inputFocus === "loginId" && !isTokenModalOpen ? "inputFocus" : ""}`}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              <input
                id="loginId"
                onKeyPress={keyPressed}
                aria-required="true"
                aria-describedby="ErrorMessage"
                name="loginId"
                onChange={e => {
                  handleChange(e);
                }}
                value={loginData.loginId}
                type="text"
                className="floating-label-field"
              />
              <label htmlFor="loginId" className={`Text20EmphasisDarkLeft floating-label ${loginData.loginId ? "focus" : ""}`}>Log on ID</label>
            </div>
            <div className={`float-panel floating-label-wrap ${inputFocus === "password" && !isTokenModalOpen ? "inputFocus" : ""}`}
              onFocus={handleFocus}
              onBlur={handleBlur}>
              <input
                id="password"
                onKeyPress={keyPressed}
                aria-required="true"
                aria-describedby="ErrorMessage"
                name="password"
                onChange={e => {
                  handleChange(e);
                }}
                value={loginData.password}
                type="password"
                className="floating-label-field"
              />
              <label htmlFor="password" className={`Text20EmphasisDarkLeft floating-label ${loginData.password ? "focus" : ""}`}>Password</label>
            </div>
            <div className={`float-panel floating-label-wrap ${inputFocus === "token" && !isTokenModalOpen ? "inputFocus" : ""}`}
              onFocus={handleFocus}
              onBlur={handleBlur}>
              <input
                id="token"
                onKeyPress={keyPressed}
                aria-describedby="ErrorMessage"
                name="token"
                type="password"
                onChange={e => {
                  handleChange(e);
                }}
                value={loginData.token}
                className="floating-label-field"
              />
              <label htmlFor="token" className={`Text20EmphasisDarkLeft floating-label ${loginData.token ? "focus" : ""}`}>Token password (if needed)</label>
            </div>
            <div className="login-box login-box px-2 px-sm-4">
              <p>
                By logging on you agree to our
                <a href={Constants.url.TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer">
                  CommBiz Terms and Conditions
                </a>
              </p>
              <div className="pb-3" tabindex="1">
                <ButtonActionEmphasis id="logUserin" onClick={(event) => { submitUser(event) }} >
                  Log on
                </ButtonActionEmphasis>
              </div>
              <p><a href={SECURITY_FORGOTPASSWORD} rel="noopener noreferrer" id="fp_link">Forgotten password?</a></p>
              <div className="register-link-mobile">
                <p><a href={redirectToRegisterUrl} target="_blank" rel="noopener noreferrer">Register for CommBiz</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {
          isRenderDialogueComponent()
        }
      </div>
    </section>
  );
}

export default LoginPanel;