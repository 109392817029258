export default {
    commbiz:"CommBiz",
    url:{
        REGISTER_ONLINE_COMMBIZ:"/registration/client/registration/RegSelectionDoAction.aspx",
        TERMS_AND_CONDITIONS:"https://www.commbank.com.au/content/dam/commbank/business/pds/004-634.PDF"
    },
    header:{
        REGISTER_ONLINE_FOR_COMMBIZ:"Register for CommBiz"
    },
    loginPannel:{
        WELCOME_TO_COMMBIZ:'Log on to CommBiz',
        LOGIN_PAGE_CONTENT:"Secure online banking for businesses of all sizes."
    },
    footer: {
        COPY_RIGHTS_CONTENT: "© Commonwealth Bank of Australia ABN 48 123 123 124 AFSL and Australian credit licence 234945",
        url:{
            SECURITY_URL:"https://www.commbank.com.au/business/online-banking/commbiz/security.html"
        }
    },
    logOffPanel:{
        LOG_OUT_COMMBIZ: "You've logged off",
        LOG_OUT_CONTENT: "",
        LOG_OUT_TITLE: "CommBiz - Log off",
        LOG_OUT_PAGE_LOGON_TEXT: "Log on"
    }
  };