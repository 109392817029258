import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import  loginUser  from './logonUserReducer';
import bioCatch from './biocatchReducer';
import adobeCJA from './adobeCjaReducer';

export const rootReducer = combineReducers({
    login:loginUser,
    routing: routerReducer,
    bioCatch, 
    adobeCJA
  });


