import React from 'react';
import {Icon} from '@honeycomb-npm/honeycomb-react';

const DialogueTokenMandatoryErrorPanel = (props) => {
  return (
    <div className="token-modal-error-msg-layout" aria-invalid="true" aria-hidden="true">
      <Icon
        symbol="messageAlert16"
        className="icon_content"
        iconClassName="icon_class"
        type="svgfile"
      ></Icon>
      <span className="token-modal-error-msg-text">{props.errorMessage}</span>
    </div>
  );
};

export {DialogueTokenMandatoryErrorPanel};
