import React from 'react';
import './styles.scss';
import AppRouter from './routers/AppRouter';

class App extends React.Component {
  render() {
    return (    
        <AppRouter />     
    );
  }
}

export default App;
