
import axiosClient from '../shared/utils/axiosClient';
import * as EndPoints from '../constants/Urls';
import { DB_KILL_SWITCH_NAME_PLACEHOLDER } from '../constants/common';
import { ADOBE_CJA_DB_KILL_SWITCH_NAME } from '../constants/adobeCJA';
import { setAdobeCjaEnabledStatus } from '../redux/actions/adobeCjaAction';

const AdobeCjaService = {
    getDBKillSwitchForAdobeCja: async (dispatch) => {
        await axiosClient.get(EndPoints.getDBKillSwitch.replace(DB_KILL_SWITCH_NAME_PLACEHOLDER, ADOBE_CJA_DB_KILL_SWITCH_NAME))
            .then(response => {
                if (response.data.data.isEnabled) {
                    dispatch(setAdobeCjaEnabledStatus(response.data.data.isEnabled));
                }
            })
            .catch(error => {
                if (error) {
                    dispatch(setAdobeCjaEnabledStatus(false));
                }
            });
    }
}

export default AdobeCjaService;