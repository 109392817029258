import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import LogoffUser from '../components/LogoffUser/logoffuser';
import Layout from '../components/Layout/Layout';
import {
    LOGIN,
    LOGIN_SIGNEDOUT,
} from '../constants/RoutePaths';
import BioCatchService from '../services/BioCatchService';
import AdobeCjaService from '../services/AdobeCjaService';

const AppRouter = (props) => {
    const dispatch = useDispatch(); 
    
    useEffect(() => {

        let getBioCatchEnabledDBKillSwitchInfo = async () => await BioCatchService.getDBKillSwitchForBioCatch(dispatch);
        getBioCatchEnabledDBKillSwitchInfo();

        let getaAdobeCjaKillswitchInfo = async() => await AdobeCjaService.getDBKillSwitchForAdobeCja(dispatch);
        getaAdobeCjaKillswitchInfo();
    }, []);

    return (
        <Switch>
            <Route
                exact
                path={LOGIN}
                render={(routeProps) => <Layout routeProps={routeProps} {...props} />}
            />
            <Route
                exact
                path={LOGIN_SIGNEDOUT}
                component={LogoffUser}
            />
        </Switch>
    );
}

export default AppRouter;
